<!-- =========================================================================================
    File Name: CardStatistics.vue
    Description: Statistics Card
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="page admin-formulas">

    <!-- Tableau -->
    <vx-card>
      <router-link to="formules/ajout" class="mb-5 float-right" icon-pack="feather" radius icon="icon-plus" size="large" >  <vs-button class="mb-5 float-right" icon-pack="feather" radius icon="icon-plus" size="large" /> </router-link>

      <div id="data-list-list-view" class="data-list-container ">

        <vs-table :data="formules">
          <template slot="thead">
            <vs-th>Titre</vs-th>
            <vs-th>Prix/Nb rendez-vous</vs-th>
            <vs-th>Actif</vs-th>
            <vs-th></vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].libelle">
                {{ data[indextr].libelle }}
              </vs-td>
              <vs-td :data="data[indextr].prix">
                <li v-for="oneItem in data[indextr].prix" :key="oneItem.nb">
                  {{ oneItem.nb }} à {{oneItem.prix}} €</li>
              </vs-td>
              <vs-td :data="data[indextr].active">
                <feather-icon v-if="data[indextr].active "  svgClasses="w-4 h-4" icon="CheckCircleIcon" class="mr-2 icon-success" />
                <feather-icon v-else  svgClasses="w-4 h-4" icon="XCircleIcon" class="mr-2 icon-danger" />
              </vs-td>
              <vs-td :data="data[indextr].active">
                <feather-icon @click="getDetails(data[indextr].id)" svgClasses="w-6 h-6" icon="EditIcon" class="mr-2 cursor-pointer" />
                <feather-icon svgClasses="w-6 h-6" @click="openConfirm(data[indextr].id)" icon="PauseCircleIcon" class="mr-2 cursor-pointer" />
              </vs-td>

            </vs-tr>
          </template>

        </vs-table>
      </div>
    </vx-card>
  </div>
</template>

<script>
import moment from 'moment';
import axios from '@/axios';
import router from '@/router';

export default {
  filters: {
    formDate(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY HH:mm:ss');
      }
    },
  },
  data() {
    return {
      formules: [],
    };
  },
  methods: {
    openConfirm(id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmer',
        text: 'Voulez-vous vraiment désactiver cette formule?',
        accept: this.acceptAlert,
        parameters: [id],
      });
    },
    acceptAlert(parameters) {
      axios.put(`/api/formule_commandes/${parameters[0]}`, { active: false }, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }).then(() => {
        this.$vs.notify({
          color: this.colorAlert,
          title: 'Suppression',
          text: 'La formule a été supprimée.',
        });
        this.getFormules();
      }).catch((error) => {
        this.$toasted.show(error).goAway(1500);
      });
    },
    getDetails(id) {
      router.push({ name: 'admin-formulas-edit-id', params: { id } });
    },
    getFormules() {
      axios.get('/api/formule_commandes', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }).then((response) => {
        if (response.data !== null) {
          this.formules = response.data;
        }
      })
        .catch((error) => {
          this.$toasted.show(error).goAway(1500);
        });
    },

  },
  created() {
    this.getFormules();
  },
};
</script>
<style>
  .admin-formulas .vs-table--tbody-table .vs-table--thead tr {
    background: #ed1e54;
    color: #fff;
  }
  .admin-formulas .vs-table--tbody-table .vs-table--thead tr .vs-table-text{
    text-transform: uppercase;
    font-weight: 600;
  }
</style>
